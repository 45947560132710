[class*="maki-"]:before {
  font-family: maki, sans-serif;
}

[class*="entypo-"]:before {
  font-family: entypo, sans-serif;
}

[class*="fontawesome-"]:before {
  font-family: FontAwesome, sans-serif;
}

body {
  min-height: 100%;
  z-index: -2;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background: url("bg.1b29cd45.jpg") top / cover no-repeat fixed;
  line-height: 20px;
  color: #9ea7b3 !important;
  margin: 0 !important;
  font-family: Open Sans, Arial, sans-serif !important;
  font-size: 13px !important;
  overflow-x: hidden !important;
}

#login-wrapper {
  z-index: 5;
  margin: 50px auto 0;
  position: relative;
}

#logo-login {
  color: #fff;
  background: #304160cc;
  border-radius: 3px 3px 0 0;
  padding: 1px 0 14px 25px;
}

#logo-login h1 {
  color: #fff;
  letter-spacing: -1px;
  text-decoration: inherit;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 200;
}

#logo-login h1 span {
  background: #0003;
  border-radius: 3px;
  margin-left: 0;
  padding: 0 5px;
  font-size: 11px;
  position: relative;
  top: -3px;
}

#login-wrapper3 {
  width: 60%;
  z-index: 200;
  background: #fff;
  border-radius: 4px;
  margin: 0 auto;
  padding: 10px;
  position: relative;
  top: 0;
  left: 0;
}

.form-signin {
  margin: 0 auto;
  padding: 15px;
}

.form-signin .form-signin-heading, .form-signin .checkbox {
  margin-bottom: 10px;
}

.form-signin .checkbox {
  font-weight: normal;
}

.form-signin .form-control {
  -moz-box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 13px;
  position: relative;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input[type="email"] {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  margin-bottom: -1px;
}

.form-signin input[type="password"] {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-bottom: 10px;
}

.input-group-addon, .btn-fltees:hover {
  color: #fff;
  background: #10abce !important;
  border: 1px solid #10abce !important;
}

.btn-fltees {
  background: #0db8df !important;
  border: 1px solid #0db8df !important;
}

.form-control:focus {
  outline: 0;
  box-shadow: none !important;
  border-color: #0db8df !important;
}

.gmap3 {
  height: 110%;
  width: 100%;
  z-index: 0;
  opacity: .3;
  margin: 0;
  top: 0;
  left: 0;
  position: fixed !important;
}

#logo-wrapper {
  width: 100%;
  height: 60px;
}

#logo-wrapper > img {
  height: 30px;
}

.account-box {
  -khtml-border-radius: 0 0 4px 4px;
  z-index: 3;
  background-color: #fff;
  border-radius: 0 0 4px 4px;
  padding: 20px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 13px !important;
}

.logo {
  width: 138px;
  height: 30px;
  text-align: center;
  background-position: 0 -4px;
  margin: 10px 0 27px 40px;
  position: relative;
}

.forgotLnk {
  margin-top: 10px;
  display: block;
}

.purple-bg {
  color: #fff;
  background-color: #6e329d;
}

.or-box {
  border-top: 1px solid #dfdfdf;
  margin-top: 80px;
  margin-bottom: 0;
  padding-top: 20px;
  position: relative;
}

.or {
  color: #666;
  text-align: center;
  width: 60px;
  background-color: #fff;
  padding: 0 10px;
  position: relative;
  top: -30px;
}

.account-box .btn:hover {
  color: #fff;
}

.btn-facebook {
  color: #fff;
  background-color: #3b5998;
  font-weight: bold;
}

.btn-google {
  color: #fff;
  background-color: #c32f10;
  font-weight: bold;
}

.btn-twitter {
  color: #fff;
  background-color: #00aced;
  font-weight: bold;
}

.btn-instagram {
  color: #fff;
  background-color: #316698;
  font-weight: bold;
}

.label-forgot {
  color: #3276b1;
  font-size: 11px;
}

.label-forgot:hover {
  text-decoration: none;
}

.space-icon {
  border-right: 1px solid #0000001a;
  margin-right: 10px;
  padding-right: 10px;
  box-shadow: 1px 0 #ffffff1a;
}

.login-with {
  text-align: center;
  background: #fff;
  margin: 0 auto;
  padding: 0 10px;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  top: -33px;
}

.login-with b {
  color: #00aced;
  cursor: pointer;
}

/*# sourceMappingURL=index.415ff7e0.css.map */
